import api from "!../../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[3]!./salesConfigurationPosConfigurationDiscountIngredients.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};